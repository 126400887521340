import { useCallback, useEffect, useMemo, useState } from "react";
import { renderToString } from 'react-dom/server';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { Box, Button } from "@mui/material";
import Container from "./Container";

const Preview = ({ file, replaceItems }) => {
  const [styles, setStyles] = useState();
  const [preview, setPreview] = useState();
  const [props, setProps] = useState();

  const options = useMemo(() => ({
    replace: (domNode) => {
      delete domNode.attribs?.onclick;
      delete domNode.attribs?.onchange;

      // If there is an overlay modal defined with an 'overlay' class, remove the class:
      if (domNode.attribs?.class) {
          if (domNode.attribs.class === 'overlay' || domNode.attribs.class === 'modalOverlay' ) {
            delete domNode.attribs.class;
          }
      }

      let { attribs, name, children, type } = domNode;

      if(type === 'text') {
        const currentValue = domNode.data.trim();
        if(currentValue) {
          const { searchText, replaceText } = replaceItems.find(item => currentValue.indexOf(item.searchText) !== -1) || {};
          if(replaceText) domNode.data = domNode.data.replace(searchText, replaceText);
        }
      }

      if(name === 'input') {
        Object.keys(attribs).map(attrib => {
          if(attrib === 'value' || attrib === 'placeholder') {
            const { replaceText } = replaceItems.find(item => item.searchText === attribs[attrib]) || {};
            if(replaceText) attribs[attrib] = replaceText;
          }
          return;
        })
        attribs = {
          'data-train-input-id': 'this-is-input',
          ...attribs
        };
      }

      if(name === 'select') {
        attribs = {
          'data-train-select-id': 'this-is-select',
          ...attribs
        };
      }

      if(name === 'textarea') {
        attribs = {
          'data-train-textarea-id': 'this-is-textarea',
          ...attribs
        };
      }

      if(name === 'a') {
        attribs = {
          'data-train-a-id': 'this-is-a',
          ...attribs,
        };
      }

      const props = attributesToProps(attribs);

      if(name === 'c:set') {
        return <div>{domToReact(children, options)}</div>
      }
      if(name === 'spring:message') {
        return <div>{domToReact(children, options)}</div>
      }

      if(name === 'script') {
        return <></>
      }

      if(name === 'a') {
        return <a {...props} style={{ cursor: 'pointer' }} onClick={() => false} href={'#noaction'}>
          {domToReact(children, options)}
        </a>
      }

      if(name === 'button') {
        return <button {...props} style={{ cursor: 'pointer' }} onClick={() => false}>
          {domToReact(children, options)}
        </button>
      }

      if(name === 'option') {
        const { defaultValue: value, selected, ...newProps } = props;

        return <option value={value} {...newProps}>
          {domToReact(children, options)}
        </option>
      }

      return domToReact({...domNode, attribs: attribs});

    }
  }), [replaceItems]);

  const onChangeFile = useCallback((data, options) => {
    if (!data) return console.error("Enter a valid file");

    const reader = new FileReader();
    reader.readAsText(data);

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const parsedHtml = parse(target.result, options);
      const { children, ...props } = parsedHtml.props;
      
      setProps(props);
      setStyles(parsedHtml.props?.children[0].props?.children.filter(item => item.type === 'style'));
      setPreview(parsedHtml.props?.children[1].props?.children);
    };
  }, []);

  useEffect(() => {
    onChangeFile(file, options);
  }, [file, options, onChangeFile]);

  //console.log(renderToString(styles));
  function download() {
    const element = document.createElement('a');
    const propsArr = Object.keys(props).map(item => `${item}="${props[item]}"`);

    element.setAttribute('href', 'data:html/plain;charset=utf-8,' + encodeURIComponent(`\ufeff
    <!DOCTYPE html>
    <html ${propsArr.join(' ')}>
      <head>
        ${renderToString(styles)}
      </head>
      <body>
        ${renderToString(preview)}
      </body>
    </html>`));
    element.setAttribute('download', file.name);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }
  
  return (
    <>
    <Container sx={{ marginY: 0, paddingY: 4 }}><Button variant="outlined" onClick={download}>Сохранить</Button></Container>
      {styles}
      <Box sx={{ position: 'relative' }}>
        {preview}
      </Box>
    </>
  );
}

export default Preview;